<template>
  <appointment-modality-specialty
    v-model="chosenSpecialty"
    :modalities.sync="partner.modalities"
    :specialties.sync="filteredMedicalSpecialties"
    :disabled.sync="ui.isLoading"
    @choose="startAppointmentReservation"
    @change="(v) => chosenModality = v"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AppointmentModalitySpecialty from '@/components/landingPage/AppointmentModalitySpecialty.vue';
import medicalSpecialties from '@/mixins/medicalSpecialties';

export default {
  name: 'AccountAppointmentScheduling',

  mixins: [medicalSpecialties],

  components: {
    AppointmentModalitySpecialty,
  },

  data() {
    return {
      chosenSpecialty: null,
      chosenModality: null,
      ui: {
        isLoading: false,
      },
    };
  },

  computed: {
    ...mapGetters('partner', ['partner', 'medicalSpecialties']),

    ...mapGetters('scheduling', ['appointment']),

    appointmentSpecialty() {
      return this.appointment.specialty;
    },

    filteredMedicalSpecialties() {
      return this.medicalSpecialties
        .filter((specialty) => specialty.modality === this.appointment.modality);
    },
  },

  watch: {
    appointmentSpecialty: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          this.chosenSpecialty = value;
        }
      },
    },

    chosenModality: {
      deep: false,
      immediate: true,
      handler(value) {
        if (value) {
          this.setAppointment({ modality: value });
        }
      },
    },

    'appointment.modality': {
      deep: false,
      immediate: false,
      handler(modality) {
        if (modality && this.chosenSpecialty) {
          const chosenSpecialty = this.medicalSpecialties
            .find((specialty) => {
              const { id } = this.chosenSpecialty;
              return specialty.id === id && specialty.modality === modality;
            });

          this.chosenSpecialty = { ...chosenSpecialty };
        }
      },
    },
  },

  mounted() {
    this.fetchMedicalSpecialties();
    this.navigator = navigator;
  },

  methods: {
    ...mapActions('ui', ['completeProgressTrackerStep']),
    ...mapActions('partner', ['setMedicalSpecialties']),
    ...mapActions('scheduling', ['setAppointment']),

    startAppointmentReservation() {
      if (this.chosenModality === 'presential') {
        this.completeProgressTrackerStep({ step: 1, status: false });
        if (this.chosenSpecialty) {
          this.setAppointment({ specialty: this.chosenSpecialty });
        }

        this.$router.push({ name: 'UserSchedulingSpecialty' });
        return;
      }

      if (this.chosenSpecialty) {
        this.setAppointment({ specialty: this.chosenSpecialty });
        this.completeProgressTrackerStep({ step: 1, status: true });
        this.$router.push({ name: 'UserSchedulingDate' });
        return;
      }

      this.$router.push({ name: 'UserSchedulingSpecialty' });
    },
  },
};
</script>
