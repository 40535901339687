<template>
  <appointments
    :items="2"
    card-width="full"
  />
</template>

<script>
import appointments from '@/components/user/medicalHistory/appointments.vue';

export default {
  name: 'AccountAppointmentList',

  components: {
    appointments,
  },
};
</script>
