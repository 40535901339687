<template>
  <div
    v-if="user"
    class="user-account"
  >
    <appointment-followup-alert />

    <account-section
      v-for="section in sections"
      :key="section.name"
      :type="section.type"
      bg="white"
    >
      <template v-slot:title>
        {{ section.title }}
      </template>

      <template v-slot:content>
        <component :is="section.component" />
      </template>
    </account-section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccountSection from '@/components/user/account/accountSection.vue';
import AppointmentScheduling from '@/components/user/account/appointmentScheduling.vue';
import AppointmentList from '@/components/user/account/appointmentList.vue';
import MedicalFiles from '@/components/user/account/medicalFiles.vue';
import AppointmentFollowupAlert from '@/components/user/ui/appointmentFollowupAlert.vue';

export default {
  name: 'UserAccount',

  components: {
    AccountSection,
    AppointmentScheduling,
    AppointmentList,
    MedicalFiles,
    AppointmentFollowupAlert,
  },

  data() {
    return {
      sections: [
        {
          name: 'appointment-scheduling',
          title: 'Agende sua consulta',
          component: 'AppointmentScheduling',
          type: 'full',
        },
        {
          name: 'appointment-list',
          title: 'Próximas consultas',
          component: 'AppointmentList',
          type: 'half',
        },
        {
          name: 'medical-files',
          title: 'Meus Documentos',
          component: 'MedicalFiles',
          type: 'half',
        },
      ],
    };
  },

  computed: {
    ...mapGetters('user', ['user']),
  },

  created() {
    if (document.domain === 'paciente.drconecta.com.br') {
      window.fbq('init', '532918017596757');
    }

    this.setAppointment({
      specialty: this.chosenSpecialty,
      modality: 'telemedicine',
    });
    this.setAddress({ latitude: null, longitude: null });
  },

  methods: {
    ...mapActions('scheduling', ['setAppointment', 'setAddress']),
  },
};
</script>
