export default {
  data() {
    return {
      deviceWidth: 0,
    };
  },

  computed: {
    device() {
      let isMobile = false;
      let isTablet = false;
      let isLarge = false;

      switch (true) {
        case this.width < 740:
          isMobile = true;
          break;

        case this.deviceWidth > 740 && this.deviceWidth < 1024:
          isTablet = true;
          break;

        case this.deviceWidth > 1024:
          isLarge = true;
          break;

        default:
          isMobile = true;
      }

      return {
        isMobile,
        isTablet,
        isLarge,
      };
    },
  },

  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    handleResize() {
      this.deviceWidth = window.innerWidth;
    },
  },
};
