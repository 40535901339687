<template>
  <section
    :class="{
      'account-section--full': type === 'full',
      'account-section--half': type === 'half',
      'account-section--white': bg === 'white2',
    }"
    class="account-section"
  >
    <header
      class="account-section__header"
      @click="collapse"
    >
      <h1 class="account-section__title">
        <slot name="title" />
      </h1>

      <i
        v-if="device.isMobile"
        :class="{ 'account-section__caret--up': !isCollapsed }"
        class="icon icon-caret account-section__caret"
      />
    </header>

    <transition
      name="fade"
      mode="out-in"
    >
      <article
        v-if="showContent"
        class="account-section__content"
      >
        <slot name="content" />
      </article>
    </transition>
  </section>
</template>

<script>
import device from '@/mixins/device';

export default {
  name: 'UserAccountSection',

  mixins: [device],

  props: {
    type: {
      type: String,
      required: false,
      validator: (value) => ['half', 'full'].includes(value),
      default: () => 'half',
    },

    bg: {
      type: String,
      required: false,
      default: () => '',
    },
  },

  data() {
    return {
      isCollapsed: true,
    };
  },

  computed: {
    showContent() {
      if (this.device.isMobile) {
        return this.isCollapsed;
      }

      return true;
    },
  },

  methods: {
    collapse() {
      if (this.device.isMobile) {
        this.isCollapsed = !this.isCollapsed;
      }
    },
  },
};
</script>
