<template>
  <section
    v-if="followupAppointments.length > 0"
    class="followup-alert account-section"
  >
    <div
      @click="ui.isOpen = !ui.isOpen"
      class="followup-alert__header"
    >
      <div>
        <strong>Atenção:</strong> você possui retorno pendente de agendamento
      </div>

      <div>
        <i
          :class="{ 'followup-alert__caret--up': ui.isOpen }"
          class="icon icon-caret followup-alert__caret"
        />
      </div>
    </div>

    <transition name="fade">
      <div
        v-if="ui.isOpen"
        class="followup-alert__body"
      >
        <ul class="followup-alert__list">
          <li
            v-for="appointment in followupAppointments"
            :key="`appointment-${appointment.id}`"
            class="followup-alert__item"
          >
            <a
              :href="getFollowupUrl(appointment)"
              target="_blank"
            >
              Clique aqui para marcar o retorno da consulta com
              o Dr(a). <strong>{{ appointment.doctor['display_name'] }}</strong>
              ({{ appointment['appointment_start'] | toFormattedDate }})
            </a>
          </li>
        </ul>
      </div>
    </transition>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { parseISO, format } from 'date-fns';
import appointmentFollowup from '@/api/user/appointmentFollowup';

export default {
  name: 'AppointmentFollowupAlert',

  filters: {
    toFormattedDate(date) {
      return format(parseISO(date), 'dd/MM/yyyy');
    },
  },

  data() {
    return {
      ui: {
        isOpen: false,
      },
      followupAppointments: [],
    };
  },

  computed: {
    ...mapGetters('user', ['headers']),
  },

  created() {
    this.toggleProgressBar();

    appointmentFollowup(this.headers)
      .getAppointments()
      .then((res) => {
        this.followupAppointments = res;
      })
      .finally(() => this.toggleProgressBar(false));
  },

  methods: {
    ...mapActions('ui', ['toggleProgressBar']),

    getFollowupUrl(appointment) {
      return `${
        process.env.VUE_APP_DOCTOR_URL
      }/public/doctor/${
        appointment.doctor.uuid
      }?agenda=${
        appointment.uuid
      }`;
    },
  },
};
</script>

<style lang="scss">
.followup-alert {
  @apply w-full text-sm;
  @apply bg-yellow-100;
  box-shadow: 0 2px 8px 2px rgba(206, 160, 24, 0.3);
  color: rgb(102, 77, 3);

  &__header {
    @apply w-full cursor-pointer;
    @apply flex flex-row items-center justify-between;
  }

  &__caret {
    @apply text-lg;
    transition: all 0.2s ease;

    &--up {
      transform: rotate(180deg);
      transition: all 0.2s ease;
    }
  }

  &__list {
    @apply mt-4;
  }

  &__item {
    @apply cursor-pointer;
    @apply ml-6 mb-2 text-xs;
    list-style-type: disc;

    a {
      color: rgb(102, 77, 3);

      &:hover {
        @apply underline;
      }
    }
  }
}
</style>
