import http from '../httpConfig';

const appointmentFollowup = (config) => {
  const headers = config;
  const endpoint = '/user/agenda/check_return';

  const getAppointments = () => new Promise((resolve) => {
    http.get(endpoint, headers)
      .then((res) => resolve(res.data))
      .catch(() => resolve([]));
  });

  return {
    getAppointments,
  };
};

export default appointmentFollowup;
